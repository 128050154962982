/* @import url("../node_modules/devextreme/dist/css/dx.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-dashboard/dist/css/dx-dashboard.light.css"); */

/* @import url("../node_modules/ace-builds/css/ace.css");
@import url("../node_modules/ace-builds/css/theme/dreamweaver.css");
@import url("../node_modules/ace-builds/css/theme/ambiance.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-richedit/dist/dx.richedit.css");
@import url("../node_modules/devextreme/dist/css/dx.material.blue.light.compact.css");
@import url("../node_modules/devexpress-dashboard/dist/css/dx-dashboard.material.blue.light.compact.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.material.blue.light.compact.css"); */

/* @import url("../node_modules/devextreme/dist/css/dx.material.blue.light.compact.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.material.blue.light.compact.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-dashboard/dist/css/dx-dashboard.material.blue.light.compact.css"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dx-toolbar-label>.dx-toolbar-item-content { line-height: 19.6px;  }
.dx-popup-title { color: #000; font-family: Open Sans; font-size: 14px;font-style: normal;font-weight: 600; }
.xfx-form#form {margin-top: 0px;}