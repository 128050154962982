.dx-icon-userCheck::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDIxVjE5QzE2IDE3LjkzOTEgMTUuNTc4NiAxNi45MjE3IDE0LjgyODQgMTYuMTcxNkMxNC4wNzgzIDE1LjQyMTQgMTMuMDYwOSAxNSAxMiAxNUg1QzMuOTM5MTMgMTUgMi45MjE3MiAxNS40MjE0IDIuMTcxNTcgMTYuMTcxNkMxLjQyMTQzIDE2LjkyMTcgMSAxNy45MzkxIDEgMTlWMjEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOC41IDExQzEwLjcwOTEgMTEgMTIuNSA5LjIwOTE0IDEyLjUgN0MxMi41IDQuNzkwODYgMTAuNzA5MSAzIDguNSAzQzYuMjkwODYgMyA0LjUgNC43OTA4NiA0LjUgN0M0LjUgOS4yMDkxNCA2LjI5MDg2IDExIDguNSAxMVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTcgMTFMMTkgMTNMMjMgOSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    mask-size: cover;
}

.dx-icon-users::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIxVjE5QzE3IDE3LjkzOTEgMTYuNTc4NiAxNi45MjE3IDE1LjgyODQgMTYuMTcxNkMxNS4wNzgzIDE1LjQyMTQgMTQuMDYwOSAxNSAxMyAxNUg1QzMuOTM5MTMgMTUgMi45MjE3MiAxNS40MjE0IDIuMTcxNTcgMTYuMTcxNkMxLjQyMTQzIDE2LjkyMTcgMSAxNy45MzkxIDEgMTlWMjEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOSAxMUMxMS4yMDkxIDExIDEzIDkuMjA5MTQgMTMgN0MxMyA0Ljc5MDg2IDExLjIwOTEgMyA5IDNDNi43OTA4NiAzIDUgNC43OTA4NiA1IDdDNSA5LjIwOTE0IDYuNzkwODYgMTEgOSAxMVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjMgMjAuOTk5OVYxOC45OTk5QzIyLjk5OTMgMTguMTEzNiAyMi43MDQ0IDE3LjI1MjcgMjIuMTYxNCAxNi41NTIyQzIxLjYxODQgMTUuODUxNyAyMC44NTgxIDE1LjM1MTUgMjAgMTUuMTI5OSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNiAzLjEyOTg4QzE2Ljg2MDQgMy4zNTAxOCAxNy42MjMgMy44NTA1OCAxOC4xNjc2IDQuNTUyMTlDMTguNzEyMiA1LjI1MzggMTkuMDA3OCA2LjExNjcxIDE5LjAwNzggNy4wMDQ4OEMxOS4wMDc4IDcuODkzMDUgMTguNzEyMiA4Ljc1NTk2IDE4LjE2NzYgOS40NTc1N0MxNy42MjMgMTAuMTU5MiAxNi44NjA0IDEwLjY1OTYgMTYgMTAuODc5OSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    mask-size: cover;
}

.dx-icon-eye::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMTJDMSAxMiA1IDQgMTIgNEMxOSA0IDIzIDEyIDIzIDEyQzIzIDEyIDE5IDIwIDEyIDIwQzUgMjAgMSAxMiAxIDEyWiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMiAxNUMxMy42NTY5IDE1IDE1IDEzLjY1NjkgMTUgMTJDMTUgMTAuMzQzMSAxMy42NTY5IDkgMTIgOUMxMC4zNDMxIDkgOSAxMC4zNDMxIDkgMTJDOSAxMy42NTY5IDEwLjM0MzEgMTUgMTIgMTVaIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    mask-size: cover;
}

.dx-icon-inbox::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDEySDE2TDE0IDE1SDEwTDggMTJIMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01LjQ1IDUuMTFMMiAxMlYxOEMyIDE4LjUzMDQgMi4yMTA3MSAxOS4wMzkxIDIuNTg1NzkgMTkuNDE0MkMyLjk2MDg2IDE5Ljc4OTMgMy40Njk1NyAyMCA0IDIwSDIwQzIwLjUzMDQgMjAgMjEuMDM5MSAxOS43ODkzIDIxLjQxNDIgMTkuNDE0MkMyMS43ODkzIDE5LjAzOTEgMjIgMTguNTMwNCAyMiAxOFYxMkwxOC41NSA1LjExQzE4LjM4NDQgNC43NzY3OSAxOC4xMjkyIDQuNDk2MzcgMTcuODEzIDQuMzAwMjhDMTcuNDk2NyA0LjEwNDE5IDE3LjEzMjEgNC4wMDAyIDE2Ljc2IDRINy4yNEM2Ljg2NzkyIDQuMDAwMiA2LjUwMzI2IDQuMTA0MTkgNi4xODcwNCA0LjMwMDI4QzUuODcwODMgNC40OTYzNyA1LjYxNTU4IDQuNzc2NzkgNS40NSA1LjExVjUuMTFaIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    mask-size: cover;
}

.dx-icon-inboxBlack::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: black;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDEySDE2TDE0IDE1SDEwTDggMTJIMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01LjQ1IDUuMTFMMiAxMlYxOEMyIDE4LjUzMDQgMi4yMTA3MSAxOS4wMzkxIDIuNTg1NzkgMTkuNDE0MkMyLjk2MDg2IDE5Ljc4OTMgMy40Njk1NyAyMCA0IDIwSDIwQzIwLjUzMDQgMjAgMjEuMDM5MSAxOS43ODkzIDIxLjQxNDIgMTkuNDE0MkMyMS43ODkzIDE5LjAzOTEgMjIgMTguNTMwNCAyMiAxOFYxMkwxOC41NSA1LjExQzE4LjM4NDQgNC43NzY3OSAxOC4xMjkyIDQuNDk2MzcgMTcuODEzIDQuMzAwMjhDMTcuNDk2NyA0LjEwNDE5IDE3LjEzMjEgNC4wMDAyIDE2Ljc2IDRINy4yNEM2Ljg2NzkyIDQuMDAwMiA2LjUwMzI2IDQuMTA0MTkgNi4xODcwNCA0LjMwMDI4QzUuODcwODMgNC40OTYzNyA1LjYxNTU4IDQuNzc2NzkgNS40NSA1LjExVjUuMTFaIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    mask-size: cover;
}

.dx-icon-checkCircle::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #2DB63A;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDExLjA3OTlWMTEuOTk5OUMyMS45OTg4IDE0LjE1NjMgMjEuMzAwNSAxNi4yNTQ1IDIwLjAwOTMgMTcuOTgxN0MxOC43MTgyIDE5LjcwODggMTYuOTAzMyAyMC45NzIzIDE0LjgzNTQgMjEuNTgzOEMxMi43Njc0IDIyLjE5NTIgMTAuNTU3MyAyMi4xMjE4IDguNTM0NDcgMjEuMzc0NEM2LjUxMTY4IDIwLjYyNzEgNC43ODQ2NSAxOS4yNDYgMy42MTA5NiAxNy40MzY5QzIuNDM3MjcgMTUuNjI3OSAxLjg3OTc5IDEzLjQ4NzkgMi4wMjE2OCAxMS4zMzYyQzIuMTYzNTYgOS4xODQ0MyAyLjk5NzIxIDcuMTM2MTkgNC4zOTgyOCA1LjQ5Njk0QzUuNzk5MzUgMy44NTc2OCA3LjY5Mjc5IDIuNzE1MjUgOS43OTYxOSAyLjI0MDAxQzExLjg5OTYgMS43NjQ3NyAxNC4xMDAzIDEuOTgyMiAxNi4wNyAyLjg1OTg2IiBzdHJva2U9IiMyREI2M0EiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjIgNEwxMiAxNC4wMUw5IDExLjAxIiBzdHJva2U9IiMyREI2M0EiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    mask-size: cover;
}

.dx-icon-syncCustom::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMMjEgNUwxNyA5IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTMgMTFWOUMzIDcuOTM5MTMgMy40MjE0MyA2LjkyMTcyIDQuMTcxNTcgNi4xNzE1N0M0LjkyMTcyIDUuNDIxNDMgNS45MzkxMyA1IDcgNUgyMSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik03IDIzTDMgMTlMNyAxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMSAxM1YxNUMyMSAxNi4wNjA5IDIwLjU3ODYgMTcuMDc4MyAxOS44Mjg0IDE3LjgyODRDMTkuMDc4MyAxOC41Nzg2IDE4LjA2MDkgMTkgMTcgMTlIMyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    mask-size: cover;
}

.dx-icon-xCircle::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #FF0000;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDIyQzE3LjUyMjggMjIgMjIgMTcuNTIyOCAyMiAxMkMyMiA2LjQ3NzE1IDE3LjUyMjggMiAxMiAyQzYuNDc3MTUgMiAyIDYuNDc3MTUgMiAxMkMyIDE3LjUyMjggNi40NzcxNSAyMiAxMiAyMloiIHN0cm9rZT0iI0ZGMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSA5TDkgMTUiIHN0cm9rZT0iI0ZGMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05IDlMMTUgMTUiIHN0cm9rZT0iI0ZGMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    mask-size: cover;
}

.dx-icon-rightFromBracketSolidToolBar::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMS4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTk2IDQ4MGg2NEMxNzcuNyA0ODAgMTkyIDQ2NS43IDE5MiA0NDhTMTc3LjcgNDE2IDE2MCA0MTZIOTZjLTE3LjY3IDAtMzItMTQuMzMtMzItMzJWMTI4YzAtMTcuNjcgMTQuMzMtMzIgMzItMzJoNjRDMTc3LjcgOTYgMTkyIDgxLjY3IDE5MiA2NFMxNzcuNyAzMiAxNjAgMzJIOTZDNDIuOTggMzIgMCA3NC45OCAwIDEyOHYyNTZDMCA0MzcgNDIuOTggNDgwIDk2IDQ4MHpNNTA0LjggMjM4LjVsLTE0NC4xLTEzNmMtNi45NzUtNi41NzgtMTcuMi04LjM3NS0yNi00LjU5NGMtOC44MDMgMy43OTctMTQuNTEgMTIuNDctMTQuNTEgMjIuMDVsLS4wOTE4IDcybC0xMjgtLjAwMWMtMTcuNjkgMC0zMi4wMiAxNC4zMy0zMi4wMiAzMnY2NGMwIDE3LjY3IDE0LjM0IDMyIDMyLjAyIDMybDEyOCAuMDAxbC4wOTE4IDcxLjFjMCA5LjU3OCA1LjcwNyAxOC4yNSAxNC41MSAyMi4wNWM4LjgwMyAzLjc4MSAxOS4wMyAxLjk4NCAyNi00LjU5NGwxNDQuMS0xMzZDNTE0LjQgMjY0LjQgNTE0LjQgMjQ3LjYgNTA0LjggMjM4LjV6Ii8+PC9zdmc+");
    mask-size: cover;
}

.dx-icon-tags::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 9H20' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 15H20' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 3L8 21' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 3L14 21' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    mask-size: contain;
}

.dx-icon-grid::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: #C9C9C9;
    mask: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3H3V10H10V3Z' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 3H14V10H21V3Z' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 14H14V21H21V14Z' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 14H3V21H10V14Z' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    mask-size: contain;
}

.dx-icon-trashBlack::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
    background-color: black;
    mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgNkg1SDIxIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE5IDZWMjBDMTkgMjAuNTMwNCAxOC43ODkzIDIxLjAzOTEgMTguNDE0MiAyMS40MTQyQzE4LjAzOTEgMjEuNzg5MyAxNy41MzA0IDIyIDE3IDIySDdDNi40Njk1NyAyMiA1Ljk2MDg2IDIxLjc4OTMgNS41ODU3OSAyMS40MTQyQzUuMjEwNzEgMjEuMDM5MSA1IDIwLjUzMDQgNSAyMFY2TTggNlY0QzggMy40Njk1NyA4LjIxMDcxIDIuOTYwODYgOC41ODU3OSAyLjU4NTc5QzguOTYwODYgMi4yMTA3MSA5LjQ2OTU3IDIgMTAgMkgxNEMxNC41MzA0IDIgMTUuMDM5MSAyLjIxMDcxIDE1LjQxNDIgMi41ODU3OUMxNS43ODkzIDIuOTYwODYgMTYgMy40Njk1NyAxNiA0VjYiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAgMTFWMTciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTQgMTFWMTciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    mask-size: cover;
}