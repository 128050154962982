@import "./variables.scss";

::-webkit-scrollbar {
    display: none;
}

.dx-toolbar {
    background-color: $base-color;
    padding: 5px 10px;
}

.dx-list-item-icon-container,
.dx-toolbar-before {
    padding-right: 0 !important;
    text-align: center;
}

.dx-list-item-content {
    padding-left: 10px !important;
}

.dx-button {
    background-color: rgba(191, 191, 191, -0.15);
    border: none;
}

.panel-list {
    height: 400px;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
    float: right;
}

.panel-list .dx-list-item {
    color: #fff;
    border-top: 1px solid rgba(221, 221, 221, 0.2);
}

.panel-list .dx-list-item .dx-icon {
    color: #fff !important;
}

.options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.options-container {
    display: flex;
    align-items: center;
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
    display: inline-block;
    margin-right: 50px;
}

label {
    font-weight: bold;
}

#content {
    height: 100%;
    padding: 10px 20px;
}

#content h2 {
    font-size: 26px;
}

.dx-toast-message {
    white-space: pre-line;
}

.widget-container {
    padding: 20px;
    max-width: 550px;
    min-width: 300px;
}

#form {
    margin-top: 25px;
}

.options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 260px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.container {
    padding: 0;
}

/* Tym robimy, żeby na 2 i kolejnych stronach nie było linii między kolumnami */
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row>td,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row>td {
    border-right: 0px;
    border-left: 0px;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 5px 4px;
}

.app .content {
    background-color: white;
}

.container .content .video {
    height: 100%;
    width: 100%;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content>.dx-scrollview-content {
    background-color: white;
}

.toolbar {
    background-color: #e3e3e3;
}

.toolbar-button-label,
.toolbar,
.toolbar-label,
.toolbar .dx-button-content,
.toolbar .dx-icon {
    /* color: #c52832; */
    color: black;
}

.dx-button-has-icon.dx-button-has-text.dx-button-mode-text .dx-button-content {
    padding-right: 20px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}

.toolbar-label,
.toolbar-label .dx-icon-preferences,
.toolbar-label .dx-icon-product,
.toolbar-label .dx-icon-box,
.toolbar-label .dx-icon-export,
.toolbar-label .dx-icon-tags,
.toolbar-label .dx-icon-textdocument {
    font-size: 24px;
    /* font-weight: bold; */
}

.toolbar-label .dx-icon-preferences,
.toolbar-label .dx-icon-product,
.toolbar-label .dx-icon-box,
.toolbar-label .svg-inline--fa {
    padding-right: 4px;
}

.toolbar-button-label {
    font-size: 14px;
    display: inline;
    vertical-align: middle;
    line-height: 23px;
    text-transform: none;
    font-weight: 500;
    letter-spacing: .04em;
}

.dx-button-text {
    text-transform: none;
}

.verticalAlignMiddle {
    vertical-align: middle;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    background-color: #6FA8BF;
}

.moduleListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



.dx-toolbar .dx-toolbar-items-container {
    height: 40px;
}

.file-uploader-block .dx-fileuploader-wrapper {
    padding-left: 0px;
    padding-top: 17px;
}

.file-uploader-block .dx-fileuploader-wrapper .dx-fileuploader-input-wrapper .dx-button {
    background-color: rgba(255, 255, 255, 1);
}

.file-uploader-block .dx-fileuploader-wrapper .dx-fileuploader-input-wrapper .dx-button-text {
    color: black !important;
}

.file-uploader-block .dx-fileuploader-wrapper .dx-fileuploader-input-wrapper .dx-button.dx-state-focused,
.file-uploader-block .dx-fileuploader-wrapper .dx-fileuploader-input-wrapper .dx-button.dx-state-hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.dx-theme-material-typography .dx-swatch-additional,
.dx-theme-material-typography.dx-swatch-additional {
    background-color: $base-color;
    color: rgb(0, 0, 0);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2857;
}

.dx-swatch-additional .dx-widget {
    color: $menu-font-color
}

.dx-swatch-additional .dx-treeview-toggle-item-visibility {
    color: rgb(0, 0, 0);
    content: "\f016";
}

.dx-swatch-additional .dx-treeview-toggle-item-visibility::before {
    content: "\f016";
}

.dx-swatch-additional .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened {
    content: "\f014"
}

.dx-swatch-additional .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
    content: "\f014"
}

.toolbar .dx-icon-ksefixInsight {
    width: 126px;
    height: 14px;
    margin-top: 6px;
}

.toolbar .dx-button {
    box-shadow: none;
}

.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 4px;
}

.organization-selectBox>.dx-texteditor.dx-editor-filled {
    background-color: rgb(255, 255, 255);
    border-bottom: none;
    height: 36px;
    width: 350px;
}

.organization-selectBox .dx-texteditor.dx-editor-filled::after {
    border-bottom: none;
}

// Side menu level 1 font style
.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level="1"] .dx-treeview-item-content {
    font-weight: 600;
}

// Side menu level 2 font style
.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level="2"] .dx-treeview-item-content {
    font-weight: 400;
}

// Table header colors
.dx-datagrid-headers .dx-datagrid-table {
    // background-color: #0A3548;
    color: #ffffff;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row {
    background-color: #0A3548;
}

.dx-cell-focus-disabled.dx-datagrid-drag-action {
    background-color: #0A3548;
}

// select input border
.dx-selectbox-container {
    border: 1px solid #C9C9C9;
    border-radius: 2px;
}

.dx-texteditor-container .dx-placeholder {
    line-height: 0px;
}

// buttons in popup
.dx-popup-normal .dx-button-text {
    color: black;
}

#mainToolbar .toolbar,
.toolbar .dx-button-content,
.toolbar .dx-icon,
.toolbar-button-label,
.toolbar-label {
    color: #ffffff;
}

.editor-rows-container td[role=columnheader] {
    text-align: center !important
}

.invoice-editor-section-7 .xfx-form .dx-widget.dx-collection {
    display: block !important;
}

.inv-number-check-box {
    padding-top: 15px;
    padding-bottom: 15px;
}

.flex-container {
    display: flex;
}

.invoice-number-tooltip-icon {
    margin-top: 12px;
    margin-left: 2px;
}