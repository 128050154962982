@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

.dx-drawer {
  height: calc(100% - 50px);
}

.menu-outer-container {
  bottom: 65px;
  width: 100%;
  height: calc(100% - 65px);
}

.bottom-expander {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $base-bg;
  font-weight: 700;
  font-size: 14px;
  color: $base-text-color;
  cursor: pointer;

  .expander-content {
    padding-top: 23px;
    padding-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%; 
  }

  .menu-arrow {
    max-height: 22px;
    padding-left: 20px;
    padding-right: 27px;
  }
}