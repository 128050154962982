.invoice-editor-version {
    margin: 10px;
}

.invoice-editor-buttons .dx-button {
    margin-left: 10px;
}

.invoice-editor-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 450pt;
    /* flex: auto; */
}

.invoice-editor-field-label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
}

.invoice-editor-header {
    display: flex;
    justify-content: space-between;
}

.invoice-editor-section {
    background: #E7EBED;
    padding: 20px;
    margin: 10px;
}

.invoice-editor-section .dx-tab-text {
    text-transform: capitalize;
}

.invoice-editor-section .dx-field-item-content {
    /* width: 640px; */
}

.invoice-editor-row {
    display: flex;
    flex-direction: row;
}

.invoice-editor-field-datebox {
    width: 140pt;
}

.invoice-editor-rows-summary-row {
    display: flex;
    flex-direction: row;
    color: #000;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 19.6px */
}

.invoice-editor-rows-summary-row div {
    margin-right: 20px;
}

.invoice-editor-rows-summary-row-header {
    min-width: 200px;
}

.invoice-editor-sequence {
    border-left: 1px solid #000;
    padding: 20px
}

.invoice-editor-sequence-element {
    border-left: 1px solid #000;
    padding: 20px;
    margin-bottom: 20px
}

.invoice-editor-sequence-summary {
    display: flex;
    align-items: center;
}

.invoice-editor-sequence-summary>* {
    margin: 5px;
}

.invoice-editor-field-invisible {
    display: none;
    background-color: red;
}

.invoice-editor-field-error {
    color: red;
    padding-left: 10px;
    font-size: smaller;
}

.invoice-editor-section-force-hide {
    display: none !important;
}

.invoice-editor-change {
    color: red;
    padding-left: 10px;
    font-size: smaller;
    font-style: italic;
}

.invoice-editor-description {
    font-style: italic;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: justify;
    color: red;
}