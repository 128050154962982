@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-icon-chevron {
      position: sticky;
      left: 341px;
      top: 30px;
    }

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;
        line-height: 19px;

        .item-first-level>.dx-icon {
          width: 60px !important;
          margin: 0 !important;
        }

        .dx-icon {
          width: 24px !important;
          margin: 0 !important;
          font-size: 1px;
        }
      }

      .menu-item-icon {
        display: inline-block;
        vertical-align: top;
      }

      .menu-item-text {
        display: inline-block;
      }

      .menu-item-text>span {
        padding: 4px 8px;
      }

      .menu-item-text>span:hover {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 2px;
      }

      .selected-item-xfx .menu-item-text>span {
        background: #6BA6BE;
        color: #000000;
        border-radius: 2px;
      }

      .selected-item-first-lvl-xfx .menu-item-text>span {
        background: #6BA6BE;
        color: #000000;
        border-radius: 2px;
      }

      .item-second-level-with-children>.menu-item-text>span:hover {
        background-color: $base-bg !important;
        border-radius: 2px;
      }

      div.dx-treeview-item-content:has(div.item-second-level-with-children) {
        cursor: default !important;
      }

      .empty-item {
        height: 27px;
      }

      .item-first-level {
        line-height: 20px;
        padding-top: 23px;
        padding-bottom: 23px;
        display: flex;
        align-items: center;

        .menu-item-text {
          padding-left: 19px;
        }

        .menu-item-icon {
          height: 24px;
          text-align: center;
        }
      }

      .item-second-level {
        line-height: 20px;
        padding-bottom: 12px;
      }

      .item-second-level-standard {
        padding-top: 20px;
      }

      .item-second-level-after-first-level {
        padding-top: 7px;
      }

      .item-second-level-prev-no-child {
        padding-top: 12px;
      }

      .item-third-level {
        line-height: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .item-third-level-before-first-lvl {
        margin-bottom: 20px;
      }

      .item-third-level::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: rgba(255, 255, 255, .3);
      }

      .item-third-level {
        position: relative;
        padding-left: 17px;
      }

      .dx-treeview-item {
        min-height: 0px;
        padding: 0px;
      }

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
        visibility: hidden;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      .dx-treeview-item-content span {
        vertical-align: top;
        white-space: normal;
      }

      .dx-treeview-item-content .item-first-level span {
        vertical-align: top;
        white-space: nowrap;
      }

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: 600;
        }

        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: 600;
          padding-left: 43px;
        }

        &[aria-level='3'] .dx-treeview-item-content {
          font-weight: 400;
          padding-left: 66px;
        }
      }

      .dx-treeview-node[aria-level="1"]+.dx-treeview-node[aria-level="1"]:before {
        position: absolute;
        content: '';
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        transform: translateX(-50%);
        width: 91%;
        left: 50%;
      }

      .dx-treeview-node[aria-level="1"][aria-expanded="false"]::after {
        width: 0%;
      }
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)>.dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected>.dx-treeview-item * {
            color: $base-accent;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused>.dx-treeview-item * {
        color: inherit;
      }
    }
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}