.treeView-hidden-collapse-button .dx-treeview-toggle-item-visibility {
    display: none !important;
}

.treeView-hidden-collapse-button .dx-widget.dx-checkbox.dx-checkbox-has-text.dx-treeview-select-all-item {
    font-weight: 600
}

.treeView-hidden-collapse-button .dx-treeview-select-all-item .dx-checkbox-text {
    padding-left: 12px;
}

.treeView-hidden-collapse-button .dx-treeview-node.dx-treeview-item-with-checkbox {
    &[aria-level='1'] .dx-item.dx-treeview-item {
        font-weight: 600
    }

    &[aria-level='2'] .dx-item.dx-treeview-item {
        font-weight: 600
    }

    // &[aria-level='2']:not(:has(ul)) .dx-widget.dx-checkbox {
    //     display: block
    // }

    // &[aria-level='2']:has(ul) .dx-widget.dx-checkbox {
    //     display: none
    // }

    &[aria-level='3'] .dx-item.dx-treeview-item {
        font-weight: 400
    }

    // &[aria-level='3']:not(:has(ul)) .dx-widget.dx-checkbox {
    //     display: block
    // }

    // &[aria-level='3']:has(ul) .dx-widget.dx-checkbox {
    //     display: none
    // }
}

.basicToolbar.dx-toolbar {
    padding-top: 20px;
    background-color: white;
    display: flex;
}

.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(1) {
    padding-right: 24px
}

.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(1) .dx-item-content.dx-toolbar-item-content .dx-button-has-text {
    background: rgba(10, 53, 72, 1);
    border-radius: 1px;
}

.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(1) .dx-item-content.dx-toolbar-item-content .dx-button-has-text.dx-state-focused,
.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(1) .dx-item-content.dx-toolbar-item-content .dx-button-has-text.dx-state-hover {
    background-color: rgba(10, 53, 72, 0.8);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(1) .dx-item-content.dx-toolbar-item-content .dx-icon {
    color: white !important
}

.basicToolbar.dx-toolbar .dx-toolbar-before>.dx-item.dx-toolbar-item.dx-toolbar-button:nth-of-type(2) .dx-item-content.dx-toolbar-item-content .dx-button-text {
    color: black !important
}

.basicToolbar.dx-toolbar .dx-toolbar-button .dx-item-content.dx-toolbar-item-content {
    border: 1px solid #0A3548;
    border-radius: 2px;
}