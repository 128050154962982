/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v34-latin-ext_latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/open-sans-v34-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/open-sans-v34-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/open-sans-v34-latin-ext_latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/open-sans-v34-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/open-sans-v34-latin-ext_latin-regular.svg#OpenSans') format('svg');
    /* Legacy iOS */
}


.dx-popup-wrapper>.dx-overlay-content {
    box-shadow: unset;
}

.dx-popup-wrapper .dx-toolbar.dx-popup-title {
    background-color: unset;
    border-bottom: none;
}

.dx-popup-wrapper .dx-toolbar-label .dx-toolbar-item-content>div {
    font-weight: 600;
    font-size: 14px;
}

.dx-popup-wrapper .dx-toolbar {
    background-color: white;
}

.dx-popup-wrapper .dx-toolbar .dx-widget .dx-visibility-change-handler .dx-collection .dx-popup-bottom {
    padding-bottom: 40px;
}

/* button */
.dx-popup-bottom .dx-toolbar-button .dx-item-content.dx-toolbar-item-content {
    border: 1px solid #0A3548;
    border-radius: 2px;
}

.dx-item.dx-toolbar-item.dx-toolbar-button.popup-toolbarItem-save,
.dx-item.dx-toolbar-item.dx-toolbar-button.popup-toolbarItem-saveDraft,
.dx-item.dx-toolbar-item.dx-toolbar-button.popup-toolbarItem-load {
    padding-right: 24px;
}

.dx-popup-bottom .dx-toolbar-button.popup-toolbarItem-load .dx-item-content.dx-toolbar-item-content {
    border: none !important;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-save .dx-button-mode-text.dx-button-default {
    background-color: rgba(10, 53, 72, 1);
    border-radius: 1px;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-saveDraft .dx-button-mode-text.dx-button-default {
    background-color: rgba(107, 167, 191, 1);
    border-radius: 1px;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-cancel .dx-button-mode-text.dx-button-default {
    border-radius: 1px;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-save .dx-button-mode-text.dx-button-default.dx-state-focused,
.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-save .dx-button-mode-text.dx-button-default.dx-state-hover {
    background-color: rgba(10, 53, 72, 0.8);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-saveDraft .dx-button-mode-text.dx-button-default.dx-state-focused,
.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-saveDraft .dx-button-mode-text.dx-button-default.dx-state-hover {
    background-color: rgba(107, 167, 191, 0.8);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-cancel .dx-button-mode-text.dx-button-default.dx-state-focused,
.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-cancel .dx-button-mode-text.dx-button-default.dx-state-hover {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-save .dx-item-content.dx-toolbar-item-content .dx-button-text,
.dx-popup-bottom .dx-toolbar-before .popup-toolbarItem-save .dx-item-content.dx-toolbar-item-content .dx-icon {
    color: white !important
}

.dx-button-text {
    font-size: 14px;
    color: white;
}

.dx-popup-content {
    border: 1px solid #C9C9C9;
    border-radius: 2px;
    margin: 0;
    padding: 0px;
}

[role="dialog"] > .dx-popup-content {
    margin: 24px 32px 0px 32px;
}

.dx-popup-bottom.dx-toolbar {
    padding: 0px 25px 0px 32px;
}

.dx-drawer-content {
    background-color: white;
}

.dx-drawer-content .container {
    padding: 20px;
}


.dx-datagrid .dx-row>td {
    padding: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
}

.dx-datagrid .dx-row td ul {
    list-style-type: disc;
}

.xfx-breadcrumb {
    font-size: 14px;
}

.xfx-breadcrumb-arrow {
    font-weight: bolder;
}

.xfx-singlebreadcrumb {
    font-size: 14px;
    font-weight: bolder;
}

.xfx-breadcrumb-arrow::after {
    content: url("data:image/svg+xml,%3Csvg width='25' height='8' viewBox='0 0 25 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5L24 4.5V3.5L0 3.5L0 4.5Z' fill='%236BA6BE' /%3E%3C/svg%3E");
    margin-left: 20px;
    margin-right: 20px;
}

.xfx-form .dx-texteditor-input {

    background-color: white;
}

.xfx-form .dx-field-item-label-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */


    color: #000000;
}

.xfx-form .dx-field-item-label.dx-field-item-label-location-top {
    padding-left: 0px;
}

.xfx-form .dx-form-group-content {
    border: none;
    padding-top: 5px;
}

.xfx-form .dx-form-group-caption {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
}


.dx-popup-content {
    border: none;
}

.xfx-form .dx-widget.dx-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.xfx-form .dx-texteditor-container {
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid #000000;
}

.xfx-form .dx-editor-filled .dx-texteditor-buttons-container:last-child>.dx-dropdowneditor-button:last-child,
.dx-editor-filled .dx-texteditor-buttons-container:last-child>.dx-numberbox-spin-container:last-child,
.dx-editor-outlined .dx-texteditor-buttons-container:last-child>.dx-dropdowneditor-button:last-child,
.dx-editor-outlined .dx-texteditor-buttons-container:last-child>.dx-numberbox-spin-container:last-child {
    margin: 0px;
    background-color: white;
}

.xfx-form .dx-selectbox-container {
    border: none;
    background-color: white;
}

.xfx-form .dx-widget.dx-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.xfx-required .dx-label span:after,
.xfx-required label span span:after {
    content: " *";
}

/* Columns selcetion */
.dx-datagrid-column-chooser-button {
    display: none;
}

.dx-datagrid-header-panel {
    display: none;
}

.dx-hidden-cell.dx-datagrid-action.dx-cell-focus-disabled {
    display: none;
    background-color: #0A3548;
}

.dx-hidden-cell.dx-editor-cell {
    display: none;
}

.dx-datagrid-action.dx-cell-focus-disabled {
    background: #0A3548;
}

.dx-header-row .dx-cell-focus-disabled {
    background: #0A3548;
}

.dx-scrollview-content>.popup_content {
    height: 20px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;

    color: #000000;
}

.dx-item.dx-toolbar-item.dx-toolbar-label {
    border-bottom: 1px solid #C9C9C9;
}

.xfx-select-tenant {
    margin-top: 50px;
    width: 500px;
    background: white;
}

.list-tenants {
    max-height: 150px;
}

.dx-button-normal.dx-button-has-icon.dx-closebutton {
    margin-right: 18px;
}

.dx-filterbuilder-overlay .dx-scrollable-container {
    max-height: 360px !important;
}

/* Overriding autogenerated props heere so we have these changes after next themes regeneration */
.dx-scrollbar-vertical .dx-scrollable-scroll {
    width: 26px !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
    width: 26px !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
    width: 26px !important;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 26px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 26px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
    height: 26px !important;
}

/* ********************************************************************************************** */