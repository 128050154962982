@import "../../themes/generated/variables.base.scss";

.single-card {

  .login-container {
    display: grid;
    background-color: #233C4E;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100vh;
  }
  
  .top-left-shape {
    left: 48px;
    top: 48px;
    position: absolute;
  }
  
  .top-right-shape {
    right: 0;
    top: 0;
      position: absolute;
  }
  
  .top-right-shape-top-part {
      right: 48px;
      top: 48px;
      position: absolute;
  }
  
  .top-right-shape-bottom-part {
      right: 92px;
      top: 93px;
      position: absolute;
  }
  
  .bottom-left-shape {
    left: 0;
    bottom: 0;
    position: absolute;
  }
  
  .bottom-right-shape {
    right: 48px;
    bottom: 48px;
      position: absolute;
  }
  
  .login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 36.6%;
    left: 50%;
    transform: translateX(-50%);
  }

  .login-element {
    top: 116px;
    position: relative;
  }

  .message-text {
    color: #FFFFFF;
  }
  
  .login-button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $base-text-color;
    cursor: pointer;
    border-radius: 2px;
    background: #FFFFFF;
    width: 149px;
    height: 54px;
  }
}
