.xfx-toolbar-bigbuttons .dx-toolbar {
  height: 100px;
  background-color: white;
  display: flex;
  /* border-bottom: 1px solid #e0e0e0; */
}

.xfx-toolbar-bigbuttons .dx-button {
  height: 60px;
  box-shadow: none;
}

.xfx-toolbar-bigbuttons .dx-button.dx-state-disabled {
  background-color: white;
}

.xfx-toolbar-bigbuttons .dx-button-content {
  border: none;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xfx-toolbar-bigbuttons .dx-toolbar-items-container {
  height: unset;
}

.xfx-toolbar-bigbuttons .dx-icon {
  color: #C9C9C9;
  margin: 0px;
}

.xfx-toolbar-bigbuttons .dx-button-text {
  color: #000000;
  font-size: 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  text-align: center;
}

.dx-icon-folderMinusIcon::before {
  width: 24px;
  height: 24px;
  display: inline-block;
  content: '';
  background-color: #C9C9C9;
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDE5QzIyIDE5LjUzMDQgMjEuNzg5MyAyMC4wMzkxIDIxLjQxNDIgMjAuNDE0MkMyMS4wMzkxIDIwLjc4OTMgMjAuNTMwNCAyMSAyMCAyMUg0QzMuNDY5NTcgMjEgMi45NjA4NiAyMC43ODkzIDIuNTg1NzkgMjAuNDE0MkMyLjIxMDcxIDIwLjAzOTEgMiAxOS41MzA0IDIgMTlWNUMyIDQuNDY5NTcgMi4yMTA3MSAzLjk2MDg2IDIuNTg1NzkgMy41ODU3OUMyLjk2MDg2IDMuMjEwNzEgMy40Njk1NyAzIDQgM0g5TDExIDZIMjBDMjAuNTMwNCA2IDIxLjAzOTEgNi4yMTA3MSAyMS40MTQyIDYuNTg1NzlDMjEuNzg5MyA2Ljk2MDg2IDIyIDcuNDY5NTcgMjIgOFYxOVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOSAxNEgxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  mask-size: cover;
}

.dx-icon-folderPlusIcon::before {
  width: 24px;
  height: 24px;
  display: inline-block;
  content: '';
  background-color: #C9C9C9;
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDE5QzIyIDE5LjUzMDQgMjEuNzg5MyAyMC4wMzkxIDIxLjQxNDIgMjAuNDE0MkMyMS4wMzkxIDIwLjc4OTMgMjAuNTMwNCAyMSAyMCAyMUg0QzMuNDY5NTcgMjEgMi45NjA4NiAyMC43ODkzIDIuNTg1NzkgMjAuNDE0MkMyLjIxMDcxIDIwLjAzOTEgMiAxOS41MzA0IDIgMTlWNUMyIDQuNDY5NTcgMi4yMTA3MSAzLjk2MDg2IDIuNTg1NzkgMy41ODU3OUMyLjk2MDg2IDMuMjEwNzEgMy40Njk1NyAzIDQgM0g5TDExIDZIMjBDMjAuNTMwNCA2IDIxLjAzOTEgNi4yMTA3MSAyMS40MTQyIDYuNTg1NzlDMjEuNzg5MyA2Ljk2MDg2IDIyIDcuNDY5NTcgMjIgOFYxOVoiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIgMTFWMTciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOSAxNEgxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  mask-size: cover;
}

.dx-icon-shareCustomIcon::before {
  width: 24px;
  height: 24px;
  display: inline-block;
  content: '';
  background-color: #C9C9C9;
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgMTJWMjBDNCAyMC41MzA0IDQuMjEwNzEgMjEuMDM5MSA0LjU4NTc5IDIxLjQxNDJDNC45NjA4NiAyMS43ODkzIDUuNDY5NTcgMjIgNiAyMkgxOEMxOC41MzA0IDIyIDE5LjAzOTEgMjEuNzg5MyAxOS40MTQyIDIxLjQxNDJDMTkuNzg5MyAyMS4wMzkxIDIwIDIwLjUzMDQgMjAgMjBWMTIiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTYgNkwxMiAyTDggNiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMiAyVjE1IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  mask-size: cover;
}

.dx-icon-send::before {
  width: 24px;
  height: 24px;
  display: inline-block;
  content: '';
  background-color: #C9C9C9;
  mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDJMMTEgMTMiIHN0cm9rZT0iI0M5QzlDOSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMiAyTDE1IDIyTDExIDEzTDIgOUwyMiAyWiIgc3Ryb2tlPSIjQzlDOUM5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  mask-size: cover;
}

.dx-icon-info::before {
  color: #0a3548;
}