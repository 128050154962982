.dataGrid .dx-editor-with-menu,
.dataGrid .dx-texteditor-container,
.dataGrid .dx-texteditor-input-container .dx-texteditor-input {
    height: 35px
}

.dataGrid .dx-datagrid-filter-row>td {
    border-right: 0px;
    border-left: 0px;
    background-color: rgb(202, 201, 201);
}

.dataGrid .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator,
.dataGrid .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
    color: white;
}

.dataGrid .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort {
    color: #6BA6BE;
}

.dataGrid .dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
    padding-top: 0px;
    padding-bottom: 0px;
}

.dataGrid .dx-widget.dx-visibility-change-handler {
    max-height: calc(100vh - 270px);
}

.dataGrid {
    max-height: calc(100vh - 270px);
}